<template>
    <div 
        class="card-exam"
    >
        <div
            class="card-exam-collapse"
            :class="visible ? null : 'collapsed'"
            :aria-controls="`collapse-${exam.id}`"
            @click="visible = !visible"
        >
            <b-row 
                align-v="center" 
                align-h="between"
            >

                <b-col xxs="12" md="auto">
                    <div class="card-exam-title">
                        <div class="card-exam-title quiz-icon">
                            <feather-icon size="20" icon="MessageCircleIcon" />
                        </div>
                        <div class="card-exam-title-text" v-text="exam.title"></div>
                    </div>
                </b-col>

                <b-col xxs="12" md="auto">
                    Cantidad de preguntas: <mark>{{ exam.qty_random_questions }}</mark>
                </b-col>

                <b-col xxs="12" md="auto">
                    Tiempo: <mark>{{ exam.time }} (minutos)</mark>
                </b-col>

                <b-col xxs="12" md="auto">
                    Máximo de intentos: <mark>{{ exam.attempts.length }}</mark>
                </b-col>

                <b-col md="auto">
                    <div class="collapse-arrow">
                        <feather-icon size="20" icon="ArrowUpIcon" v-if="visible" />
                        <feather-icon size="20" icon="ArrowDownIcon" v-if="!visible" />
                    </div>
                </b-col>

            </b-row>
        </div>
        <b-collapse :id="`collapse-${exam.id}`" v-model="visible" class="mt-1">
            <b-card>
                <ExamAttempt v-for="(attempt, index) in exam.attempts" :key="index" :attempt="attempt" />
            </b-card>
        </b-collapse>
    </div>
</template>

<script>

    import ExamAttempt from '@core/components/app-exam/ExamAttempt.vue';

    export default {
        props: {
            exam: {
                type: Object,
                required: true
            }
        },
        components: {
            ExamAttempt
        },
        data() {
            return {
                visible: false
            }
        }
    }

</script>

<style lang="scss" scoped>

    .card-exam {
        .card-exam-collapse {
            box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .5) !important;
            border-radius: .25rem;
            cursor: pointer;
            color: #343434;
            padding: 1rem .75rem;
            background-color: #FFFFFF;
            border: 1px solid var(--primary);
            border-radius: 5px;
        }

        mark {
            background: rgba(234, 198, 9, .60);
            border-radius: 5px;
            color: #424242;
            font-size: 13px;
            font-weight: bold;
        }

        .card-exam-title {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;

            &.quiz-icon {
                background-color: var(--primary);
                margin-right: 15px;
                border-radius: 100%;
                min-width: 40px;
                height: 40px;

                svg {
                    color: #FFFFFF;
                }
            }

            .card-exam-title-text {
                color: #000000;
                width: 350px;
            }
        }

        .collapse-arrow {
            border: 1px solid #DD242D;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #DD242D;
            border-radius: 3px;
        }

        svg {
            color: #FFFFFF;
        }
    }

</style>