<template>
    <b-row>

        <b-col md="12">
            
            <b-alert
                variant="danger"
                :show="exams.length === 0"
            >
                <h4 class="alert-heading">SIN EXÁMENES</h4>
                <div class="alert-body">
                    No se encontró ningún examen disponible para este curso.
                </div>
            </b-alert>

        </b-col>

        <b-col md="12" v-for="exam in exams" :key="exam.id">
            <ExamItem :exam="exam" />
        </b-col>
    </b-row>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ref, onBeforeMount, onUnmounted } from '@vue/composition-api';
    import ExamItem from '@core/components/app-exam/ExamItem.vue';
    import { useToast } from 'vue-toastification/composition';
    import courseStoreModule from '../../courseStoreModule';
    import store from '@/store';

    export default {
		props: {
            courseData: {
                type: Object,
                required: true
            }
        },
        components: {
            ExamItem
        },
		setup(props) {

            // USE TOAST
			const toast = useToast();

			// REFS
            const courseData = ref(props.courseData);
            const exams = ref([]);

            const COURSE_APP_STORE_MODULE_NAME = 'app-course'

			// REGISTER MODULE
			if (!store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.registerModule(COURSE_APP_STORE_MODULE_NAME, courseStoreModule)

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.unregisterModule(COURSE_APP_STORE_MODULE_NAME)
			});

            // CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( async () => {
                await fetchExams();
            });

            const fetchExams = async () => {

                store.dispatch('app-course/fetchCourseExams', { slug: courseData.value.slug })
                    .then(response => {
                        exams.value = response.data.exams;
                    })
                    .catch(() => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error al obtener la lista de exámenes',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
                    });

            };

			return {
                // REFS
                exams
			}
		}
    }

</script>