<template>
    <div>
        <b-button
            v-if="attempt.state === 1 && attempt.enabled"
            variant="success"
            size="sm"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            @click="startExam"
        >
            <feather-icon size="20" icon="UnlockIcon" />
            <span>REALIZAR EXAMEN</span>
        </b-button>

        <b-button
            v-if="attempt.state === 1 && !attempt.enabled"
            variant="secondary"
            size="sm"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            disabled
        >
            <feather-icon size="20" icon="LockIcon" />
            <span>REALIZAR EXAMEN</span>
        </b-button>

        <b-button
            v-if="attempt.state === 2 && attempt.enabled"
            variant="warning"
            size="sm"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            @click="startExam"
        >
            <feather-icon size="20" icon="PlayCircleIcon" />
            <span>CONTINUAR EXAMEN</span>
        </b-button>
    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import courseStoreModule from '@views/modules/students/courses/courseStoreModule';
    import { useToast } from 'vue-toastification/composition';
    import { ref, onUnmounted } from '@vue/composition-api';
    import VueSweetalert2 from 'vue-sweetalert2';
    import Ripple from 'vue-ripple-directive';
    import router from '@/router';
    import store from '@/store';
	import Vue from 'vue';    
	import 'animate.css';
	
	Vue.use(VueSweetalert2);

    export default {
        props: {
            attempt: {
                type: Object,
                required: true
            }
        },
        directives: {
            Ripple
        },
        setup(props) {

            // USE TOAST
			const toast = useToast();

            // REFS
            const attempt = ref(props.attempt);

            const COURSE_APP_STORE_MODULE_NAME = 'app-course'

			// REGISTER MODULE
			if (!store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.registerModule(COURSE_APP_STORE_MODULE_NAME, courseStoreModule)

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.unregisterModule(COURSE_APP_STORE_MODULE_NAME)
			});
            
            // METHODS
            const startExam = () => {

                const confirmButtonText = attempt.value.state === 1 ? 'Sí, comenzar!' : 'Sí, continuar!';
		
                Vue.swal({
                    title: `¡Recuerde que tiene un tiempo de "${attempt.value.time} minutos" para realizar el examen!`,
                    text: 'Una vez comience la evaluación, se contará como un intento más, pero tiene 2 intentos para realizar el examen si así lo desea. Se tomará la nota más alta.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText,
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {

                    if (result.value) 
                    {
                        const { course, exam, number } = attempt.value;

                        store.dispatch('app-course/startExam', { course, exam, attempt: number })
                            .then( () => router.push({ name: 'students-courses-exams-attempt-start', params: { slug: course, examslug: exam, attempt: number } }) )
                            .catch( (err) => {
                                const message = err.response.data.message;
                                
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: message,
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });

                            })
                    }

                });

            };

            return {
                // METHODS
                startExam
            }

        }
    }

</script>

<style lang="scss" scoped>

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold !important;

        span {
            margin-left: .4rem;
        }
    }

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>