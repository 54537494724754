<template>
    <b-row>

        <b-col xs="12">
            <b-card>

                <BButtonDownloadExcel :data="getDataTableExport()" :name="getExcelName()" />

                <b-table
                    ref="table"
                    striped
                    hover
                    responsive
                    class="position-relative text-center"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="items"
                    :fields="columns"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :busy="isBusy"
                    show-empty
                    empty-text="No se encontraron registros coincidentes"
                >

                    <template #table-busy>
                        <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle mb-1"></b-spinner>
                            <div><b>CARGANDO</b></div>
                        </div>
                    </template>

                    <template #head()="data">
                        <span v-html="data.label"></span>
                    </template>

                    <template #cell(enumeration)="data">
                        {{ data.index + 1 }}
                    </template>

                    <template #cell(state)="data">
                        <b-badge :variant="data.item.statecolor">
                            {{ data.item.statename }}
                        </b-badge>
                    </template>

                    <template #cell(score)="data">
                        <mark v-if="data.item.mark">{{ data.item.score }}</mark>
                        <span v-else>{{ data.item.score }}</span>
                    </template>

                </b-table>

            </b-card>
        </b-col>

    </b-row>
</template>

<script>

    import BButtonDownloadExcel from '@core/components/b-button-download-excel/BButtonDownloadExcel.vue';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ref, onBeforeMount, onUnmounted } from '@vue/composition-api';
    import { useToast } from 'vue-toastification/composition';
    import courseStoreModule from '../../courseStoreModule';
    import store from '@/store';

    export default {
        props: {
            courseData: {
                type: Object,
                required: true
            }
        },
        components: {
            BButtonDownloadExcel
        },
        setup(props) {

            // USE TOAST
			const toast = useToast();

            // REFS
            const courseData = ref(props.courseData);
            const items = ref([]);

            const perPage = ref(50000);
            const currentPage = ref(1);
            const sortBy = ref('');
            const sortDesc = ref(false);
            const sortDirection = ref('asc');

            const isBusy = ref(true);

            const columns = ref([
                {
                    key: 'enumeration',
                    label: 'ITEM',
                    sortable: true
                },
                {
                    key: 'exam',
                    label: 'EXAMEN',
                    sortable: true
                },
                {
                    key: 'attempt',
                    label: 'INTENTO',
                    sortable: true
                },
                {
                    key: 'state',
                    label: 'ESTADO',
                    sortable: true
                },
                {
                    key: 'score',
                    label: 'PUNTAJE',
                    sortable: true
                },
                {
                    key: 'startDate',
                    label: 'FECHA INICIO',
                    sortable: true
                },
                {
                    key: 'endDate',
                    label: 'FECHA FIN',
                    sortable: true
                }
            ]);
            
            const COURSE_APP_STORE_MODULE_NAME = 'app-course';

            // REGISTER MODULE
            if (!store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.registerModule(COURSE_APP_STORE_MODULE_NAME, courseStoreModule);

            // UNREGISTER ON LEAVE
            onUnmounted(() => {
                if (store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.unregisterModule(COURSE_APP_STORE_MODULE_NAME);
            });

            onBeforeMount( async () => {
                await getDataTable();
            });

            // METHODS
            const getDataTable = async () => {

                isBusy.value = true;
                
                store.dispatch('app-course/fetchGrades', { slug: courseData.value.slug })
                    .then(response => {

                        const { evaluations } = response.data;

                        items.value = evaluations;
                        perPage.value = evaluations.length;

                        sortBy.value = '';
                        sortDesc.value = false;
                        sortDirection.value = 'asc';

                    })
                    .catch( (err) => {

                        const message = err.response.data.message ? err.response.data.message : 'Error al obtener la lista de las notas.';

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });

                    })
                    .finally( () => isBusy.value = false);

            };

            const getExcelName = () => `${courseData.value.slug}-${new Date().getTime()}.xls`;

            const getDataTableExport = () => {
                
                const array = [];

                const rows = items.value;

                for (let a = 0; a < rows.length; a++)
                {
                    const obj = {
                        'ITEM': (a + 1),
                        'EXAMEN': rows[a]['exam'],
                        'INTENTO': rows[a]['attempt'],
                        'ESTADO': rows[a]['statename'],
                        'PUNTAJE': rows[a]['score'],
                        'FECHA INICIO': rows[a]['startDate'],
                        'FECHA FIN': rows[a]['endDate']
                    };

                    array.push(obj);
                }

                return array;

            };

            return {
                // REFS
                items,
                perPage,
                currentPage,
                sortBy,
                sortDesc,
                sortDirection,
                columns,
                isBusy,

                // METHODS
                getDataTableExport,
                getExcelName,
            }
        }
    }

</script>

<style lang="scss" scoped>

    mark {
        background: rgba(234, 198, 9, .60);
        border-radius: 5px;
        color: #424242;
        font-size: 13px;
        font-weight: bold;
    }

</style>