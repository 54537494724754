<template>
    <b-row 
        class="card-exam-attempt"
        align-v="center" 
        align-h="between"
    >

        <b-col xxs="12" md="auto">
            <div class="card-exam-attempt-title">
                <div class="card-exam-attempt-title bell-icon">
                    <feather-icon size="20" icon="BellIcon" />
                </div>
                <div class="card-exam-attempt-title-text" v-text="attempt.title"></div>
            </div>
        </b-col>

        <!-- (!==) POR COMENZAR -->
        <b-col
            v-if="attempt.state !== 1"
            class="card-exam-attempt-dates"
            xxs="12"
            md="auto"
        >
            Fecha inicio: <strong>{{ attempt.startDate }}</strong> <br>
            Fecha fin: <strong>{{ attempt.endDate }}</strong>
        </b-col>

        <!-- (===) TERMINADO -->
        <b-col
            v-if="attempt.state === 3"
            class="card-exam-attempt-grade"
            xxs="12"
            md="auto"
        >
            Calificación: <strong>{{ attempt.score }}</strong>
        </b-col>

        <b-col xxs="12" md="auto">
            <ExamAttemptActionButton :attempt="attempt" />
        </b-col>

    </b-row >
</template>

<script>

    import ExamAttemptActionButton from '@core/components/app-exam/ExamAttemptActionButton.vue';

    export default {
        props: {
            attempt: {
                type: Object,
                required: true
            }
        },
        components: {
            ExamAttemptActionButton
        }
    }

</script>

<style lang="scss" scoped>

    .card-exam-attempt {
        margin-bottom: 1.5rem;
        border-bottom: 1px solid #F1F1F1;
        padding-bottom: 1.5rem;

        &:nth-last-child(1) {
            margin-bottom: 0;
            border-bottom: none;
            padding-bottom: 0;
        }

        .card-exam-attempt-dates,
        .card-exam-attempt-grade {
            color: #000000;
        }

        .card-exam-attempt-title {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;

            &.bell-icon {
                background-color: #2DA397;
                margin-right: 15px;
                border-radius: 100%;
                width: 40px;
                height: 40px;

                svg {
                    color: #FFFFFF;
                }
            }

            .card-exam-attempt-title-text {
                color: #000000;
            }
        }
    }

</style>