import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchCourses(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/students/courses', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchCourse(ctx, { slug }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/students/courses/${slug}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchCourseExams(ctx, { slug }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/students/courses/${slug}/exams`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        videoPlay(ctx, { slug }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/students/courses/${slug}/video/play`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        startExam(ctx, { course, exam, attempt }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/students/courses/${course}/exams/${exam}/attemp/${attempt}/start`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchGrades(ctx, { slug }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/students/courses/${slug}/grades`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}